import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import SeekerCV from "./Pages/SeekerCV";
import Error from "./Pages/Error";

function App() {
  return (
    <>
      <Router>
        <>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/:refCode/:id" exact element={<SeekerCV />} />
            <Route path="*" exact element={<Error />} /> {/* Catch-all route for 404 */}
          </Routes>
        </>
      </Router>
    </>
  );
}

export default App;

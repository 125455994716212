import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Error() {
	return (
		<>
			<div
				style={{
					display: "flex",
					width: "100%",
					height: "100vh",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
					flexDirection: "column",
				}}
			>   
                <div style={{ maxWidth: "32rem" }}>
				<img
					style={{width: "100%", objectFit: "cover"}}
					src="https://stage.jobsineducation.net/_next/image?url=https%3A%2F%2Fstage.jobsineducation.net%2Fimages%2Foops.jpg&w=2048&q=75"
				></img>
				<p
					style={{
						fontSize: "5rem",
						margin: "15px 0px",
						color: "rgb(42, 87, 152)",
						fontWeight: 700,
					}}
				>
					404
				</p>
				<p
					style={{
						margin: "0px 0px 50px",
						lineHeight: "1.1",
						color: "rgb(28, 27, 27)",
						fontSize: "1.5rem",
					}}
				>
					Sorry Page Not Found !
				</p>
				<Link
					to="/"
					style={{
						background: "rgb(42, 87, 152)",
						padding: "12px 46px",
						borderRadius: "8px",
						color: "#fff",
						border: "none",
						minWidth: "64px",
						lineHeight: "1.75",
						cursor: "pointer",
						textDecoration: "none",
					}}
				>
					Back To Home
				</Link>
                </div>
			</div>
		</>
	);
}

export default Error;

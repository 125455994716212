import React, { useEffect } from "react";

function Home() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV_TYPE?.toLowerCase() == "staging") {
      window.location.href = "https://stage.jobsineducation.net/";
    }else if(process.env.REACT_APP_ENV_TYPE?.toLowerCase().includes('prod')){
        window.location.href = "https://www.jobsineducation.net";
    }
  }, []);

  return null;
}

export default Home;

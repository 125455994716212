import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function SeekerCV() {
  const params = useParams();

  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState("")

  const fetchSeekerCV = async () => {
    console.log(params);
    let url;
    if (process.env.REACT_APP_ENV_TYPE?.toLowerCase() === "staging") {
      url = `${process.env.REACT_APP_STAGING_BASE_URL}/protected-seeker-resume/${params.refCode}/${params.id}`;
    } else if (process.env.REACT_APP_ENV_TYPE?.toLowerCase().includes("prod")) {
      url = `${process.env.REACT_APP_PROD_BASE_URL}/protected-seeker-resume/${params.refCode}/${params.id}`;
    }
    await axios
      .get(url)
      .then((res) => {
        console.log(res.data);
        setFileUrl(res.data.data.url);
      })
      .catch((e) => {
        setError(e.response.data.error)
        console.log(e);
      });
  };

  useEffect(() => {
    if (params) {
      fetchSeekerCV();
    }
  }, [params]);

  useEffect(() => {
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileUrl.split("/").pop(); // Optional: Specify a custom filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [fileUrl]);

  return <>{error&& error.message}</>;
}

export default SeekerCV;
